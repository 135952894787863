/* Every other row */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #ececec;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #fcfcfc;
}

.drp-cell-accent-1 {
  font-weight: bold;
}

.drp-cell-error {
  background-color: #ff8282;
}

.drp-job-datagrid-measurement-cell-inflow-temperature {
  background-color: #ffadad;
}

.drp-job-datagrid-measurement-cell-return-temperature {
  background-color: #b1c1ff;
}

.drp-job-datagrid-measurement-cell-room-temperature {
  background-color: #e6b4ff;
}

.drp-cell-warning {
  background-color: #ffd382;
}

.drp-row-error {
  background-color: #ffc8c8;
  border-top: 1px solid #c51e1e;
  border-bottom: 1px solid #c51e1e;
}

.MuiDataGrid-row.drp-row-error:nth-child(even) {
  background-color: #ffa9a9;
}

/* Flags */
.drp-row-flag {
  background-color: #c7ceff;
}

.drp-row-flag:nth-child(even) {
  background-color: #a2ade5;
}

/*
Recharts fixes for printing
*/
@media print {
  /*
    Recalculate width in print mode to fix the bug the graph isn't responsive when print.
    */
  .recharts-wrapper,
  .recharts-surface,
  .recharts-legend-wrapper {
    width: 100% !important;
  }

  /*
    Prevent recharts-legend-item have space between <svg> icon and <span> due to recalculate width: 100%
    try width: fit-content not work on safari
     */
  .recharts-legend-item > svg {
    width: auto !important;
    display: inline-block !important;
    right: inherit !important; /* align to left */
  }
}
